@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .menu__list {
    @apply text-base md:text-[13px] lg:text-base capitalize inline-block font-medium px-4 md:px-2  lg:px-4  rounded-md md:rounded-none  border-b-2 border-transparent text-gray-500  w-full  py-2 lg:py-[30px]  hover:border-b-2 hover:border-green-600 hover:text-green-600 cursor-pointer;
  }
  .sub_menu {
    @apply text-base md:text-[13px] lg:text-base capitalize flex justify-between font-medium px-4 md:px-2  lg:px-4  rounded-md md:rounded-none  border-b-2 border-transparent text-gray-500  w-full  py-2 cursor-pointer  hover:border-b-2 hover:border-green-600 hover:text-green-600 hover:bg-green-50;
  }
  .dropdown__list {
    @apply text-base md:text-[13px] lg:text-base capitalize inline-flex justify-between md:py-1 lg:px-4 lg:py-2  font-medium px-4 py-2 rounded-md lg:rounded-none  border-b-2 border-transparent text-gray-500 w-full;
  }
  .sub_dropdown__list {
    @apply text-base md:text-[13px] lg:text-base capitalize inline-flex justify-between md:py-1 lg:px-4 lg:py-2 font-medium px-4 py-2 rounded-md lg:rounded-none  border-b-2 border-transparent text-gray-500 hover:border-b-2 w-full;
  }
}
