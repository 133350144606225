.history-list {
  overflow: hidden;
}
.data-expire-true {
  position: relative;
  right: 15px;
}
.data-expire-true::before {
  position: absolute;
  content: '';
  width: 90px;
  height: 90px;
  background: #b5ebb4;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}
.data-expire-false {
  position: relative;
  right: 15px;
}
.data-expire-false::before {
  position: absolute;
  content: '';
  width: 90px;
  height: 90px;
  background: #ffebba;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}
