.profile_image {
  position: relative;
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 50%;
}
.image_input_overlay {
  background-color: #8080806e;
  width: 150px;
  height: 150px;
  position: absolute;
  top: 104px;
  left: 0;
  right: 0;
}
.profile_image img {
  border-radius: 50%;
  margin: 0 auto;
  border: 2px solid greenyellow;
  padding: 2px;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.camera_icon {
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translate(-50%, -0);
  display: grid;
  place-items: center;
  color: #fff;
  cursor: pointer;
}
.image_input {
  position: absolute;
  top: 109px;
  left: 50%;
  transform: translate(-50%, 0%);
  cursor: pointer;
  opacity: 0;
}
