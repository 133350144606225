.breadcrumb {
}
.breadcrumb ol {
}
.breadcrumb ol li {
  position: relative;
}
.breadcrumb ol li:not(:first-child)::before {
  content: '/';
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #6c757d;
}
.breadcrumb ol li a {
}
