@font-face {
  font-family: 'Tiro Bangla';
  src: url('./assets/font/TiroBangla-Regular.ttf');
}

@font-face {
  font-family: 'Kalpurush';
  src: url('./assets/font/Kalpurush.ttf');
}

body {
  /* font-family: 'Segoe UI', Arial, sans-serif, 'Tiro Bangla'; */
  /* font-family: system-ui, 'Kalpurush'; */
  font-family: system-ui, 'Tiro Bangla';
}

#root {
  /* position:relative;
	height:100%; */
  --toastify-color-success: #075f0a;
}

footer.footer {
  /* position:absolute;
	bottom:0;
	width:100%; */
}
.single-shift:hover {
  transform: scale(1.05);
  transition: 400ms ease;
  cursor: pointer;
}
.btn-gradient {
  background: rgb(18, 103, 16);
  background: linear-gradient(90deg, rgba(18, 103, 16, 1) 0%, rgba(123, 178, 78, 0.8799894957983193) 100%);
}
.font-gradient {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background: rgb(18, 103, 16);
  background: linear-gradient(90deg, rgba(18, 103, 16, 1) 0%, rgba(123, 178, 78, 0.8799894957983193) 100%);
}

.bg-gradient {
  background: rgb(7, 95, 10);
  background: linear-gradient(90deg, rgba(7, 95, 10, 1) 0%, rgba(129, 183, 82, 1) 100%);
}
.active.bg_gradient_active {
  background: rgb(7, 95, 10);
  background: linear-gradient(90deg, rgba(7, 95, 10, 1) 0%, rgba(129, 183, 82, 1) 100%);
  color: #fff;
}
.hover_gradient:hover {
  transition: ease-in;
  background: rgb(7, 95, 10);
  background: linear-gradient(180deg, rgba(7, 95, 10, 1) 0%, rgba(129, 183, 82, 1) 100%);
}
.cloud__image {
  top: 11%;
  right: 17%;
}

.ft_inc_kg {
  top: 50%;
  right: 2px;
  padding: 5px 12px;
  transform: translate(0, -50%);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 16px;
  text-transform: capitalize;
}

/* owl carousel css  */

.play_store_btn a img {
  width: 150px !important;
}
.owl-carousel .owl-stage-outer .owl-stage .owl-item > div {
  height: 650px;
  display: grid;
  place-items: center;
}
@media screen and (max-width: 640px) {
  .owl-carousel .owl-stage-outer .owl-stage .owl-item > div {
    height: 400px;
  }
}
@media screen and (max-width: 768px) {
  .owl-carousel .owl-stage-outer .owl-stage .owl-item > div {
    height: 450px;
  }
}
@media screen and (max-width: 1024px) {
  .owl-carousel .owl-stage-outer .owl-stage .owl-item > div {
    height: 450px;
  }
}
@media screen and (max-width: 1280px) {
  .owl-carousel .owl-stage-outer .owl-stage .owl-item > div {
    height: 550px;
  }
}
.owl-carousel .owl-nav {
  margin-top: 0 !important;
}
.owl-carousel .owl-nav button {
  border: 0 !important;
  color: #fff !important;
  font-size: 25px !important;
  height: 50px;
  left: 50px;
  line-height: 35px;
  margin-top: -25px;
  opacity: 0.8;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  position: absolute;
  text-align: center;
  top: 50%;
  width: 50px;
  border-radius: 50% !important;
  background-color: rgb(255, 255, 255, 0.6) !important;
}
.owl-carousel .owl-nav button:hover {
  background-color: rgba(129, 183, 82, 1) !important;
}
.owl-carousel .owl-nav button.owl-next {
  left: auto;
  right: 50px;
}
.owl-carousel .owl-dots {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 4%;
}
.owl-theme .owl-dots .owl-dot span {
  background-color: #fff !important;
}
.owl-theme .owl-dots .owl-dot.active span {
  background-color: rgba(7, 95, 10, 1) !important;
}
.owl-theme .owl-dots .owl-dot span:hover {
  background-color: rgba(7, 95, 10, 1) !important;
}

/* pagination menu
------------------
*/

.active .page_link_pagination {
  background-color: #72c961;
  color: #fff;
}
.page-item.active:hover .page_link_pagination {
  background-color: #72c961;
}
.pagination-class {
  color: #000;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}
.pagination-active-class {
  color: #000;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
  background-color: #b8e6b6;
}
.pagination_menu li:first-child:hover {
  color: rgb(170, 236, 170);
}
.pagination_menu li:last-child:hover {
  color: rgb(170, 236, 170);
}
.pagination li.disabled {
  display: none;
}
/* math equation css
--------------------
*/
.math_equation {
  position: relative;
}
.tooltip_arraw {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #000;
  position: absolute;
  top: -13px;
  right: 2px;
}

/* student profile image
------------------------
*/
.student_profile_image {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

/* header dropdown menu
=======================
*/
/* .sub_dropdown_menu{
}
.sub_dropdown_menu ul{
	display:none;
}
.sub_dropdown_menu:hover ul{
	display:block;
} */

/* Chrome, Safari, Edge, Opera  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield !important;
}

.password-show-hide {
  position: relative;
}

.password-show-hide .eye-button {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translate(0, -50%);
}

.password-show-hide .eye-button img {
  cursor: pointer;
}

/*===============================================================================*/
/*=============================== Checkbox Design ===============================*/
/*===============================================================================*/
/* The container */
.gradient-checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.gradient-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.gradient-checkbox-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border: 1px solid #bdbdbd;
  border-radius: 3px;
}

/* When the checkbox is checked, add a blue background */
.gradient-checkbox-container input:checked ~ .checkmark {
  background: linear-gradient(to bottom, #075f0a, #81b752);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.gradient-checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.gradient-checkbox-container .checkmark:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
@media screen and (max-width: 767px) {
  .institutionlogo {
    display: none;
  }
}

.fontAwesomeLoading {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.apexcharts-legend-marker {
  cursor: initial !important;
}
